import React from 'react'
import ReactDOM from 'react-dom'
import AddForm from './addForm.js'
import ShareBar from './shareBar.js'
import './index.css'


class App extends React.Component {

  constructor (props){
      super(props)
  }

  render() {
    return (
      <div>
        <AddForm />
        <br /><br /><br />
        <ShareBar />
        <div><br /><br /> <a href="./terms.html">Terms of Use</a> </div>
      </div>
      )
  }


}


ReactDOM.render(
  <App />, document.getElementById('root')
);



import React from 'react';
import ReactDOM from 'react-dom';
import './addForm.css' 


class AddForm extends React.Component {

  constructor(props) {

  	super(props)
  }


	render() {
    return( 
          <div>
            
            <img src="./corona.jpg" width="50" />
            <h2 class="brandA">COVID Risk Calculator</h2>
            <br/><br/><br/>

   			 <div>
      			Coming soon. 
   			 </div>

    </div>
        )
	}



}

export default AddForm;